import styled from "@emotion/styled";

export const ObjectDetailsTable = styled.div`
  overflow-y: auto;
  flex: 1;
  padding: 1rem;
`;

type GridContainerProps = { cols?: number };

export const GridContainer = styled.div<GridContainerProps>`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.25rem;
`;

export interface GridItemProps {
  colSpan?: number;
}

export const GridCell = styled.div<GridItemProps>`
  grid-column: span 1;
`;

export const FitImage = styled.img`
  max-width: 100%;
  max-height: 18rem;
  object-fit: contain;
  margin: 1rem auto;
`;
